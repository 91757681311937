/* eslint-disable react-hooks/rules-of-hooks */
import React, {
    useState,
    useEffect,
    useContext,
    createContext,
    useCallback,
    useRef,
    ReactNode,
} from "react";
import { ApolloProvider } from "@apollo/client";
import Hub, { HubCallback } from "@aws-amplify/core/lib-esm/Hub";
import { Auth, CognitoUser } from "@aws-amplify/auth";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import isNil from "lodash/isNil";

import { Client, GraphqlClient } from "./client";
import { GetUserMeta } from "./query/users";
import {
    GetUserMetaQuery,
    GetUserMetaQueryVariables,
    UserPermissions,
    AutocorpPermissions,
    Permissions,
} from "./graphql/types";
import { ProductType } from "@api/products";
import { mfq } from "@utils/mouseflow";
import { mp } from "@utils/mixpanel";
import { navigate } from "@utils/navigate";
import { fireLogin, fireLogout } from "@utils/gtm";
import { getError } from "@utils/errors";
import { captureError } from "@utils/sentry";

import type {
    AWSUserToken,
    IJwtTokenPayload,
    ALSUser,
    ALSCompany,
    ALSGroup,
    CompanyPermissionIndex,
} from "~/model/als";
import { pendo } from "@utils/pendo";

export type { ALSUser, ALSCompany, ALSGroup };

export type CognitoId = ReturnType<
    UnwrapPromise<ReturnType<typeof Auth["currentSession"]>>["getIdToken"]
>;

type AuthChallenge = (
    | "NEW_PASSWORD_REQUIRED"
);

interface AuthUser extends CognitoUser {
    challengeName: AuthChallenge;
}

interface IAvaAuthProviderProps {
    children: ReactNode | ReactNode[];
}

interface IImpersonate {
    id?: number;
    restricted?: boolean;
}

type ProductAvailability = Partial<Record<ProductType, boolean>>;

interface IAuthContext {
    user: ALSUser;
    selectedCompany?: ALSCompany;
    permissions?: UserPermissions;
    companyAdmin?: boolean;
    companyOverride: string;

    signin: (email: string, password: string) => Promise<void>;
    signout: () => Promise<void>;
    changePass: (newPass: string) => Promise<void>;
    impersonateCompany: (company?: number) => Promise<void>;
    changeCompany: (company: number) => void;
    toggleRestrictedView: (setVal?: boolean) => void;
    setCompanyOverride: (company: string) => void;

    firstAuth: boolean;
    loading: boolean;
    requireNewPass: boolean;
    restrictedView: boolean;
}

export const AuthContext = createContext({} as IAuthContext);

export class AvaAuthImpl {
    private userToken: AWSUserToken = null;
    private idToken: CognitoId | null = null;
    private jwtToken: string | null = null;
    private companies: ALSCompany[] = [];
    private selectedCompany?: ALSCompany = null;
    private selectedGroup?: ALSGroup;
    private impersonate?: ALSGroup;
    private permissions?: NonNullable<UserPermissions>;
    private companyPermissions?: CompanyPermissionIndex;
    private superAdmin = false;
    private admin = false;
    private companyAdmin = false;
    private managesPeople = false;
    private products: ProductAvailability = {};

    private graphqlClient: Client;

    constructor() {
        Auth.configure({
            userPoolId: process.env.GATSBY_USER_POOL,
            userPoolWebClientId: process.env.GATSBY_USER_POOL_CLIENT,
            authenticationFlowType: "USER_PASSWORD_AUTH",
        });
        this.graphqlClient = Client.getInstance(this);

        this.managesPeopleFor = this.managesPeopleFor.bind(this);
        this.hasProduct = this.hasProduct.bind(this);
    }

    public getIdToken = async (force?: boolean): Promise<CognitoId | null> => {
        if (!force && this.idToken) return this.idToken;

        try {
            return this.idToken = (await Auth.currentSession()).getIdToken();
        } catch (err) {
            return null;
        }
    }

    public getJwtToken = async (force?: boolean): Promise<string | null> => {
        if (!force && this.jwtToken) return this.jwtToken;

        try {
            const idToken = await this.getIdToken(force);
            if (idToken) {
                return this.jwtToken = idToken.getJwtToken();
            }
        } catch (err) {
            // noop
        }
        return null;
    }

    private getUserToken = async (force?: boolean): Promise<AWSUserToken> => {
        if (!force && this.userToken) return this.userToken;

        try {
            const idToken = await this.getIdToken(force);
            if (idToken) {
                await this.getJwtToken(false);
                return this.userToken = idToken.payload as IJwtTokenPayload;
            }
        } catch (err) {
            // noop
        }
        return null;
    };

    public get token(): AWSUserToken {
        return this.userToken;
    }

    public get jwt() {
        return this.jwtToken;
    }

    public managesPeopleFor(group?: number) {
        if (!group) return false;
        const company = this.companyPermissions?.[group];
        if (company) {
            return company.permissions.includes(
                Permissions.ManageUsers,
            );
        }
        return false;
    }

    public hasProduct(product: ProductType, companyId?: number): boolean {
        if (companyId) {
            const company = this.companies.find((comp) => comp?.id === companyId);
            if (company) {
                const foundProduct = company.companyProducts.nodes.some(({
                    product: companyProduct,
                }) => (
                    companyProduct?.name === product
                ));
                if (foundProduct) return true;
            }
            return false;
        }
        return this.products[product] ?? false;
    }

    private processPermissions = () => {
        this.superAdmin = this.permissions?.autocorp === AutocorpPermissions.SuperAdmin;
        this.admin = this.superAdmin || this.permissions?.autocorp === AutocorpPermissions.Admin;
        this.companyPermissions = this.permissions?.companies?.reduce((acc, companyPermission) => {
            const {
                primaryGroup,
            } = this.companies.find((comp) => comp?.id === companyPermission?.id) || {};

            return Object.assign(acc, primaryGroup && {
                [primaryGroup]: companyPermission,
            });
        }, {});

        const selectedCompanyPermissions = (
            this.selectedCompany?.primaryGroup != null
            && this.companyPermissions
            && this.companyPermissions[this.selectedCompany?.primaryGroup]
        );

        this.companyAdmin = (
            selectedCompanyPermissions
            && selectedCompanyPermissions.permissions.includes(Permissions.Admin)
            || false
        );

        this.managesPeople = this.managesPeopleFor(this.selectedCompany?.primaryGroup);
        this.products = this.companies.reduce((acc, company) => {
            company?.companyProducts.nodes.forEach(({ product }) => {
                if (product?.enabled && product.name) {
                    acc[product.name] = true;
                }
            });

            return acc;
        }, {} as ProductAvailability);

        if (
            (this.permissions?.companies.length ?? 0) === 0 &&
            (this.permissions?.autocorp ?? AutocorpPermissions.None) === AutocorpPermissions.None
        ) {
            // User does not have permission to use the portal
            throw "You do not have permission to access the portal!  Please contact your company administrator.";
        }
    }

    private selectCompany = (index?: number, companyId?: number) => {
        if (isNil(index) && !isNil(companyId)) {
            index = this.companies.findIndex((c) => (
                c?.id === companyId
            ));
        }
        if (isNil(index) || index === -1) {
            return;
        }

        this.selectedCompany = this.companies[index];
        this.selectedGroup = this.selectedCompany?.groupByPrimaryGroup ?? undefined;

        if (this.selectedCompany) {
            this.selectedCompany.widgetId = get(this.selectedCompany.widgets.nodes, "0.id", "");
            if (this.selectedCompany.widgetId) {
                this.selectedCompany.ctaTheme = get(this.selectedCompany.widgets.nodes, "0.ctaTheme", "");
            }
        }

        this.processPermissions();
    }

    private populateUserMeta = async ({
        companyId,
        networkOnly = false,
    } = {} as {
        companyId?: number;
        networkOnly?: boolean;
    }) => {
        const client = this.client;
        const token = this.token;

        if (!client || !token) return;

        try {
            const result = await client.query<GetUserMetaQuery, GetUserMetaQueryVariables>({
                query: GetUserMeta,
                variables: {
                    id: companyId,
                },
                fetchPolicy: networkOnly ? "network-only" : "cache-first",
            });

            const data = cloneDeep(result.data);

            this.companies = data.companyDetails?.nodes || [];
            this.permissions = data.currentUserPermissions ?? undefined;

            this.selectCompany(0);
            this.impersonate = companyId ? this.selectedGroup : undefined;

            if (!this.permissions) {
                throw new Error("You do not have permission to access the portal!");
            }
        } catch (err) {
            let exception = getError(err);

            if (
                exception &&
                typeof exception === "object" &&
                "networkError" in exception
            ) {
                const networkError = exception.networkError || {};
                const statusCode = networkError.statusCode;
                switch (statusCode) {
                    case 401: {
                        exception = new Error("Access Denied: Invalid Token");
                        break;
                    }
                    case 403: {
                        exception = new Error("Access Denied: Unauthorized");
                        break;
                    }
                }
            }
            if (err !== exception) {
                console.log(err);
            }
            // console.error(exception);
            captureError(`Failed to query the company for account ${companyId}`, {
                error: err,
            });
            throw exception;
        }
    };

    private reset = (): void => {
        this.userToken = null;
        this.selectedCompany = null;
        this.selectedGroup = null;
        this.permissions = undefined;
        this.graphqlClient.clearCache();
    };

    public get client(): GraphqlClient {
        return this.graphqlClient.client;
    }

    public get user(): ALSUser {
        if (!this.userToken) {
            throw new Error("You must authenticate before you can use the user token!");
        }

        return Object.assign(
            {},
            this.userToken,
            {
                superAdmin: this.superAdmin,
                autocorpAdmin: this.admin,
                managesPeople: this.managesPeople,
                permissions: this.permissions,
                selectedCompany: this.selectedCompany,
                companyAdmin: this.companyAdmin,
                selectedGroup: this.selectedGroup,
                companyPermissions: this.companyPermissions,
                companies: this.companies,
                impersonating: this.impersonate,

                managesPeopleFor: this.managesPeopleFor,
                hasProduct: this.hasProduct,
            },
        );
    }

    public get canImpersonate() {
        return this.superAdmin;
    }

    public Provider: React.FC<IAvaAuthProviderProps> = ({ children }) => {
        const [loading, setLoading] = useState(true);
        const [user, setUser] = useState<ALSUser>(null);
        const [requireNewPass, setRequireNewPass] = useState(false);
        const [impersonate, setImpersonate] = useState<IImpersonate>();
        const [companyOverride, setCompanyOverride]= useState("");
        const confirmUser = useRef<AuthUser>();
        const firstAuth = useRef(true);
        const restrictedView = impersonate?.restricted ?? true;

        const successReset = useCallback(() => {
            confirmUser.current = undefined;
            setLoading(false);
        }, []);

        const failureReset = useCallback((skipLoginEvent?: boolean) => {
            if (!skipLoginEvent) mp.fireEvent({ event: "loginFailed" });
            setLoading(false);
        }, []);

        const clearSession = useCallback(() => {
            this.reset();
            setUser(null);
            successReset();
        }, [successReset]);

        const updateUser = useCallback(() => {
            setUser(this.user);
        }, []);
        const clearUser = useCallback(() => {
            setUser(null);
        }, []);

        const impersonateCompany = useCallback(async (companyId?: number) => {
            if (!this.admin && companyId != null) {
                throw "Not authorized to switch Company accounts!";
            }

            setImpersonate((curSettings) => ({
                ...curSettings,
                id: companyId,
            }));
        }, []);

        const changeCompany = useCallback((companyId: number) => {
            this.selectCompany(undefined, companyId);
            updateUser();
        }, [updateUser]);

        const toggleRestrictedView = useCallback(async (
            setVal?: boolean,
        ) => {
            setImpersonate((curSettings) => {
                // console.log("curSettings:", {
                //     ...curSettings,
                //     restricted: !restrictedView,
                // });
                const restricted = setVal ?? !(
                    curSettings?.restricted ?? true
                );
                return {
                    ...curSettings,
                    restricted,
                };
            });
        }, []);

        useEffect(() => {
            if (impersonate) {
                this.populateUserMeta({
                    companyId: impersonate.id,
                    networkOnly: false,
                }).then(() => {
                    this.graphqlClient.evictRestrictedQueries({
                        // Set/unset inside of `this.populateUserMeta()`
                        impersonate: this.impersonate?.cognitoName,
                        restrictView: impersonate.restricted,
                    });
                    updateUser();
                });
            }
        }, [impersonate, restrictedView, updateUser]);

        const initSession = useCallback(async (skipLoginEvent?: boolean) => {
            const user = await this.getUserToken(true);
            firstAuth.current = false;

            if (user) {
                fireLogin(user.email);
                mfq.set("userEmail", user.email);
                await this.populateUserMeta({ networkOnly: true });
                updateUser();
                if (!skipLoginEvent) {
                    mp.fireEvent(
                        { event: "loginSuccess" },
                        {
                            dealerId: this.selectedCompany?.id,
                            userEmail: user.email,
                        },
                    );
                }
                pendo.register({
                    visitor: {
                        id: user.email,
                        email: user.email,
                        full_name: [user.firstName, user.lastName].filter(Boolean).join(" "),
                    },
                    account: {
                        id: `${this.selectedCompany?.id}`,
                        name: this.selectedCompany?.name!,
                    },
                });
            } else {
                clearUser();
                throw "Unable to initialize authenticated client! Please contact support.";
            }

            successReset();
        }, [successReset, updateUser, clearUser]);

        const signin: IAuthContext["signin"] = useCallback(async (email, password) => {
            setLoading(true);
            this.reset();
            try {
                const user: AuthUser = await Auth.signIn(email, password);
                switch (user.challengeName) {
                    case "NEW_PASSWORD_REQUIRED": {
                        confirmUser.current = user;
                        setRequireNewPass(true);
                        setLoading(false);
                        break;
                    }
                    default: {
                        await initSession();
                    }
                }
            } catch (err) {
                if (err.code === "PasswordResetRequiredException") {
                    navigate({
                        path: "/forgot",
                        query: {
                            email,
                        },
                    }, {
                        saveState: true,
                        keepQuery: true,
                    });
                    return;
                }
                console.error(err);
                failureReset();
                throw err;
            }
        }, [initSession, failureReset]);

        const changePass: IAuthContext["changePass"] = useCallback(async (newPass) => {
            const userAuth = confirmUser.current;
            try {
                if (userAuth) {
                    setLoading(true);
                    await new Promise<void>((resolve, reject) => {
                        userAuth.completeNewPasswordChallenge(
                            newPass,
                            {},
                            {
                                onSuccess: () => {
                                    setRequireNewPass(false);
                                    resolve();
                                },
                                onFailure: (err) => {
                                    reject(err);
                                },
                            },
                        );
                    });
                    await initSession();
                } else {
                    throw new Error(`You cannot call changePass() without receiving a "NEW_PASSWORD_REQUIRED" challenge first`);
                }
            } catch (err) {
                console.error(err);
                failureReset();
                throw err;
            }
        }, [failureReset, initSession]);

        const signout: IAuthContext["signout"] = useCallback(async () => {
            await Auth.signOut({ global: false })
                .catch((err) => {
                    console.error(err);
                    captureError("Failed to signout!", { error: err });
                });
            mp.fireEvent({ event: "logout" });
        }, []);

        // Initialize session on mount
        useEffect(() => {
            initSession(true).catch(() => {
                failureReset(true);
            });
        }, [failureReset, initSession]);

        // Subscribe to sign-in & sign-out events
        useEffect(() => {
            const hubListener: HubCallback = ({ payload: { event } }): void => {
                switch (event) {
                    case "signOut":
                        fireLogout();
                        clearSession();

                        break;
                }
            };

            Hub.listen("auth", hubListener);

            // Cleanup subscription on unmount
            return () => {
                Hub.remove("auth", hubListener);
            };
        }, [initSession, clearSession]);

        return (
            <ApolloProvider client={this.client}>
                <AuthContext.Provider
                    value={{
                        // Auth claims
                        user,
                        selectedCompany: user?.selectedCompany,
                        permissions: user?.permissions,
                        companyAdmin: user?.companyAdmin,

                        // Actions
                        signin,
                        signout,
                        changePass,
                        impersonateCompany,
                        changeCompany,
                        toggleRestrictedView,
                        setCompanyOverride,

                        // Triggers/switches
                        firstAuth: firstAuth.current,
                        loading,
                        requireNewPass,
                        restrictedView,
                        companyOverride,
                    }}
                >
                    {children}
                </AuthContext.Provider>
            </ApolloProvider>
        );
    }
}

export const AvaAuth = new AvaAuthImpl();
export type AvaAuth = typeof AvaAuth;

export const useAuth = (): IAuthContext => {
    return useContext(AuthContext);
};
export const getUser = (): AvaAuthImpl["user"] => {
    return AvaAuth.user;
};
export const getClient = (): GraphqlClient => {
    return AvaAuth.client;
};
export const getToken = (): AvaAuthImpl["token"] => {
    return AvaAuth.token;
};
export const getJwt = (): AvaAuthImpl["jwt"] => {
    return AvaAuth.jwt;
};