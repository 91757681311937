import yn from "yn";
import { ReadyPromise } from "@autocorp/web-core-utils/lib/ReadyPromise";

export const MOUSEFLOW_READY = "__mouseflowReady";
export const mfReadyPromise = new ReadyPromise();

export const useMouseflow = (
    ["production", "demo"].includes(
        process.env.GATSBY_ACTIVE_ENV || "development",
    )
    || yn(process.env.GATSBY_WITH_MOUSEFLOW, { default: false })
);

if (!useMouseflow) mfReadyPromise.cancel();

declare global {
    interface Global {
        mouseflow?: MouseflowGlobalObj;
    }
    interface Window {
        mouseflow?: MouseflowGlobalObj;
        _mfq?: MouseflowQueue;
        [MOUSEFLOW_READY]?: VoidFunction | true;
    }
}

type MouseflowGlobalObj = { start: VoidFunction } & Record<any, unknown>;
type MfqArrayItems = (
    | [string]
    | [string, unknown]
    | [string, unknown, unknown]
);
type MfqCmd = MfqArrayItems | VoidFunction;
type MouseflowQueue = Array<MfqCmd>;

export type MouseflowSessionVariables = {
    userEmail?: string;
}

const MAX_RETRIES = 5;
let isRecording = false;
let variables = {} as MouseflowSessionVariables;

/** Start Mouseflow recording */
const start = () => {
    if (isRecording || !useMouseflow) return;

    isRecording = true;
    mfReadyPromise.then(() => {
        let retries = 0;
        function doStart() {
            if (!window.mouseflow) {
                if (++retries > MAX_RETRIES) {
                    isRecording = false;
                    return;
                }

                setTimeout(() => {
                    console.warn("Mouseflow not detected yet; retrying...");
                    doStart();
                }, 1000);
                return;
            }

            window.mouseflow.start();
            pushVariables();
        }

        doStart();
    });
};

const getMfqInstance = () => {
    return window._mfq = window._mfq || [];
};

const pushVariables = (key?: string) => {
    if (!isRecording) return;

    const inst = getMfqInstance();
    if (key) {
        const useKey = key as keyof MouseflowSessionVariables;
        inst.push(["setVariable", key, variables[useKey]]);
    } else {
        Object.keys(variables).forEach((key) => {
            inst.push(["setVariable", key, variables[key as keyof MouseflowSessionVariables]]);
        });
    }
};

/** Set custom variables (key-value pairs) in Mouseflow */
function set(variables: MouseflowSessionVariables): void;
function set<K extends keyof MouseflowSessionVariables>(key: K, value: MouseflowSessionVariables[K]): void;
function set<K extends keyof MouseflowSessionVariables = keyof MouseflowSessionVariables>(
    input: MouseflowSessionVariables | K,
    value?: MouseflowSessionVariables[K],
) {
    if (typeof input === "object") {
        variables = input as MouseflowSessionVariables;
        pushVariables();
    } else {
        const key = input;
        if (value) {
            variables[key] = value;
            pushVariables(key);
        }
    }
}

export const mfq = { start, set };