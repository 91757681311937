import { TypographyStyleOptions } from "@material-ui/core/styles/createTypography";

export const fontFamilies = {
    heavy: "Baloo Bhai",
    present: "Baloo Bhai 2",
    normal: "Roboto",
    fallback: ["sans-serif"],
};

export type FontFamilyCategories = keyof typeof fontFamilies;

type GeneratedTypographyStyleOptions = Pick<TypographyStyleOptions,
    | "fontFamily"
    | "fontWeight"
    | "fontSize"
    | "lineHeight"
    | "letterSpacing"
    | "marginTop"
    | "marginBottom"
>;

interface ICreateFontArgs {
    family: FontFamilyCategories;
    size?: number | string;
    weight?: number;
    height?: number | string;
    spacing?: number | string;
    verticalSpace?: number | string;
}

interface ICreateFont {
    (args: ICreateFontArgs): GeneratedTypographyStyleOptions;
}

export const createFont: ICreateFont = ({
    family,
    size = 16,
    weight = 400,
    height = 24,
    spacing,
    verticalSpace,
}: ICreateFontArgs) => (Object.assign<GeneratedTypographyStyleOptions, GeneratedTypographyStyleOptions>(
    {
        fontFamily: [fontFamilies[family]]
            .concat(fontFamilies.fallback)
            .map((font) => `'${font}'`)
            .join(", "),
        fontWeight: weight,
        fontSize: size,
        lineHeight: height,
        letterSpacing: spacing,
    }, verticalSpace ? {
        marginTop: verticalSpace,
        marginBottom: verticalSpace,
    } : {},
));