import React, { Fragment, useCallback } from "react";
import { useSnackbar as useSnackbarContext, ProviderContext, SnackbarAction } from "notistack";
import { defaultToastTimeout } from "~/meta";
import { ToastCloseButton } from "./ToastCloseButton";
import { arrayify } from "@utils/obj";

const useSnackbar = (strict: boolean): ProviderContext | undefined => {
    const context = useSnackbarContext();

    if (!context && strict) {
        throw new Error("You cannot use the `toast` component from outside of its Provider!");
    }

    return context;
};

interface IToastOptions {
    toastTimeout?: number,
    persist?: boolean,
    action?: SnackbarAction,
}

export const useToastContext = (strict = true) => {
    const snackbar = useSnackbar(strict);

    const addToastError = useCallback((toastMessage: string, options?: IToastOptions) => {

        const useTimeout = options?.toastTimeout || defaultToastTimeout;

        const snackbarActions: SnackbarAction = (key) => {
            const actions = typeof options?.action === "function"
                ? options.action(key)
                : options?.action;

            return (
                <Fragment>
                    {actions}
                    <ToastCloseButton
                        onClick={() => snackbar?.closeSnackbar(key)}
                    />
                </Fragment>
            );
        };

        const key = snackbar?.enqueueSnackbar(toastMessage, {
            variant: "error",
            autoHideDuration: useTimeout,
            persist: options?.persist,
            action: snackbarActions,
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
            },
        });

        return key;
    }, [snackbar]);

    const removeToastError = useCallback((key: string | string[]) => {
        arrayify(key).forEach((k) => snackbar?.closeSnackbar(k));
    }, [snackbar]);


    return { addToastError, removeToastError };
};