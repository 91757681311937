import yn from "yn";
import { ReadyPromise } from "@autocorp/web-core-utils/lib/ReadyPromise";
import { ProductType } from "~/model/products";
import { AnalyticsType } from "@graphql/types";

export const MIXPANEL_READY = "__mixpanelReady";
export const WITH_MIXPANEL = yn(process.env.GATSBY_WITH_MIXPANEL, { default: false });
export const mpReadyPromise = new ReadyPromise();
export const useMixpanel = process.env.GATSBY_ACTIVE_ENV === "production" || WITH_MIXPANEL;

if (!useMixpanel) mpReadyPromise.cancel();

declare global {
    interface Global {
        mixpanel?: Mixpanel;
    }
    interface Window {
        mixpanel?: Mixpanel;
        [MIXPANEL_READY]?: VoidFunction | true;
    }
}

type CreateTriggerOptions<T extends Record<string, unknown>> = {
    [K in keyof T]: T[K] extends undefined
        ? {
            event: K;
        } : {
            event: K;
            context: T[K];
        }
}[keyof T];
type MixpanelUserEvents = {
    openedLeadDetail: {
        leadId: string;
        products: Array<ProductType>;
    };
    fullyViewedLeadDetail: {
        leadId: string;
        products: Array<ProductType>;
    };
    requestedIdVerification: {
        leadId: string;
    };
    openedIdVerificationDetail: {
        leadId: string;
    };
    printedLead: {
        leadId: string;
        products: Array<ProductType>;
    };
    openedNotes: {
        leadId: string;
    };
    savedNotes: {
        leadId: string;
    };
    addedDeliveryMethod: {
        deliveryType: string;
        deliveryValue: string | string[];
    };
    editedDeliveryMethod: {
        deliveryType: string;
        oldDeliveryValue: string;
        newDeliveryValue: string;
    };
    deletedDeliveryMethod: {
        deliveryType: string;
        deliveryValue: string;
    };
    addedCompanyAnalytic: {
        analyticsType: AnalyticsType;
        analyticsId: string;
        analyticsSecret: string;
        domain: string;
    };
    editedCompanyAnalytic: {
        id: string | number;
        analyticsType: AnalyticsType;
        analyticsId: string;
        analyticsSecret?: string;
        domain?: string;
    };
    deletedCompanyAnalytic: {
        id: string | number;
        analyticsType: AnalyticsType;
        analyticsId: string;
        analyticsSecret?: string;
        domain?: string;
    };
    searchedUsers: {
        searchString: string;
    };
    invitedUser: {
        affectedUserEmail: string;
        userRole: string;
    };
    resetUserPassword: {
        affectedUserEmail: string;
        userRole: string;
    };
    deletedUser: {
        affectedUserEmail: string;
    };
    openedSupport: undefined;
    filteredLeads: {
        searchString: string;
        dateRange: string;
        showCreditVerified: boolean;
    };
    exportedCSV: {
        searchString: string;
        dateRange: string;
        showCreditVerified: boolean;
    };
    loginSuccess: undefined;
    loginFailed: undefined;
    logout: undefined;
    routeChanged: {
        from?: string;
        to: string;
    };
};
export type MixpanelEventOptions = CreateTriggerOptions<MixpanelUserEvents>;
type DealerPortalAppContext = {
    dealerId?: number;
    userEmail: string;
}

const getMixpanelInstance = () => {
    return window.mixpanel = window.mixpanel || {} as Mixpanel;
};

/**
 * Registers/attaches a set of super properties with every event sent to Mixpanel
 * @param properties An associative array of properties to store with each fired event
 */
const register = (properties: Record<string, unknown>) => {
    const mixpanelInst = getMixpanelInstance();
    mpReadyPromise.then(() => {
        mixpanelInst.register(properties);
    });
};

/**
 * Fires an event to be tracked in Mixpanel
 * @param eventOptions Options that include the name of the event itself and any context variables that may be required
 * @param appContext Dealer Portal app context which consists of `dealerId` and `userEmail`
 */
const fireEvent = (
    eventOptions: MixpanelEventOptions,
    appContext?: DealerPortalAppContext,
) => {
    const mixpanelInst = getMixpanelInstance();
    mpReadyPromise.then(() => {
        mixpanelInst.track(eventOptions.event, (eventOptions as unknown as Record<"context", any>).context);
        if (appContext) mixpanelInst.register(appContext);
    });
};

export const mp = { fireEvent, register };