import { createTheme } from "@material-ui/core/styles";
import { TypographyOptions } from "@material-ui/core/styles/createTypography";
import { fontFamilies, createFont } from "./font-utils";
import { palette } from "./palette";

export const customClasses = [
    "heavyPresentationTitle",
    "presentationTitle",
    "tableTitle",
    "inputLabel",
    "title",
    "groupTitle",
    "navWidget",
    "dialogTitle",
    "buttonLabel",
] as const;

const typography: TypographyOptions = {
    fontFamily: Object.values(fontFamilies).map((family) => `'${family}'`).join(", "),
    fontSize: 16,
    fontWeightLight: 200,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 800,
    customClasses,

    dialogTitle: createFont({
        family: "present",
        size: 28,
        height: "38px",
        weight: 600,
    }),
    navWidget: createFont({
        family: "normal",
        size: 20,
        height: "24px",
    }),
    heavyPresentationTitle: createFont({
        family: "heavy",
        size: 48,
        height: "48px",
    }),
    presentationTitle: createFont({
        family: "present",
        size: 41,
        height: "56px",
    }),
    tableTitle: createFont({
        family: "heavy",
        size: 16,
        weight: 400,
        height: "24px",
    }),
    title: createFont({
        family: "heavy",
        size: 30,
        weight: 400,
        height: "57px",
    }),
    groupTitle: createFont({
        family: "heavy",
        size: 18,
        height: "28px",
    }),
    inputLabel: createFont({
        family: "heavy",
        size: 16,
        weight: 400,
        height: "24px",
    }),
    h1: createFont({
        family: "heavy",
        size: "2.75em",
        weight: 400,
        height: "57px",
    }),
    h2: createFont({
        family: "heavy",
        size: "2.25em",
        weight: 400,
        height: "57px",
    }),
    h3: createFont({
        family: "heavy",
        size: "1.75em",
        weight: 400,
        height: "24px",
    }),
    h4: createFont({
        family: "heavy",
        size: "1.25em",
        weight: 400,
        height: "24px",
        verticalSpace: 10,
    }),
    subtitle1: createFont({
        family: "normal",
        size: 16,
        weight: 400,
        height: "21px",
    }),
    subtitle2: createFont({
        family: "normal",
        size: 13,
        weight: 400,
        height: "20px",
    }),
    button: createFont({
        family: "heavy",
        size: 18,
        weight: 500,
        height: "29px",
    }),
    buttonLabel: createFont({
        family: "normal",
        size: 16,
        weight: 400,
        height: "24px",
    }),
    body1: createFont({
        family: "normal",
        size: 16,
        weight: 400,
        height: "24px",
    }),
    body2: createFont({
        family: "normal",
        size: 15,
        weight: 400,
        height: "24px",
    }),
};

export const theme = createTheme({
    palette,
    typography,
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 1280,
            lg: 1650,
            xl: 1920,
        },
    },

    shape: {
        borderRadius: 14,
        authFormGutter: 20,
    },

    dashboard: {
        innerContent: {
            paddingTop: 20,
        },
    },

    overrides: {
        MuiCssBaseline: {
            "@global": {
                html: {
                    width: "100%",
                    height: "100%",
                },
                body: {
                    width: "100%",
                    height: "100%",
                },
                "*": {
                    boxSizing: "border-box",
                },
                // "div.zls-sptwndw.siqembed": {
                //     left: 138,
                // },
            },
        },
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: palette.background!.default!,
            },
        },
    },
});

export default theme;