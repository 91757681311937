import { ReadyPromise } from "@autocorp/web-core-utils";

import { mfq, mfReadyPromise } from "@utils/mouseflow";
import { mp, mpReadyPromise } from "@utils/mixpanel";

import { MOUSEFLOW_READY, MIXPANEL_READY } from "~/root";
import { WindowLocation } from "~/model/view";

import type { GatsbyBrowser, RouteUpdateArgs } from "gatsby";

const onScriptLoad = (readyPromise: ReadyPromise, key: string) => {
    const ready: VoidFunction | true | undefined = (window as { [key: string]: any })[key];

    if (ready == null) {
        (window as { [key: string]: any })[key] = () => {
            readyPromise.ready();
        };
    } else {
        if (ready === true) readyPromise.ready();
    }
};

export const onClientEntry: GatsbyBrowser["onClientEntry"] = () => {
    onScriptLoad(mfReadyPromise, MOUSEFLOW_READY);
    onScriptLoad(mpReadyPromise, MIXPANEL_READY);
};

interface IOnRouteUpdateArgs extends RouteUpdateArgs {
    location: WindowLocation;
    prevLocation: WindowLocation;
}
type OnRouteUpdate = (args: IOnRouteUpdateArgs) => void;

export const onRouteUpdate: OnRouteUpdate = ({ location, prevLocation }) => {
    // Avoid running actions/events on splash screen
    if (location.pathname === "/") return;
    mfq.start();

    if (!location.state?.skipRouteChangeEvent) {
        mp.fireEvent({
            event: "routeChanged",
            context: {
                from: prevLocation?.pathname.concat(prevLocation.search),
                to: location.pathname.concat(location.search),
            },
        });
    }
};

export { wrapRootElement, wrapPageElement } from "~/root";