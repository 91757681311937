import {
    navigate as gatsbyNavigate,
    parsePath,
} from "gatsby";
// import { findMatchPath } from "@gatsby-cache/find-path";
import {
    NavigateOptions,
} from "@reach/router";
import omit from "lodash/omit";
// import { captureError } from "@utils/sentry";
import { isEmpty } from "@utils/obj";
import { normalizeUrl, parseQuery, stringifyQuery, slash } from "./url";
import type { ILocationState, WindowLocation } from "~/model/view";

export interface IUrlOpts {
    path?: string;
    query?: Record<string, unknown> | string;
    hash?: string;
}

export interface INavigateFn<R = Promise<void>> {
    (
        path: string | number | IUrlOpts,
        options?: INavigateOptions,
        location?: WindowLocation
    ): R;
}

export interface IComponentNavigateFn<R = Promise<void>> {
    (
        path: string | number | IUrlOpts,
        options?: INavigateOptions,
    ): R;
}

export interface INavigateOptions extends NavigateOptions<ILocationState> {
    back?: boolean;
    /** Fallback back path */
    backPathFallback?: string;
    savePath?: boolean;
    saveState?: boolean;
    keepQuery?: boolean;
    removeQuery?: string[];
    // validate?: boolean;
}

interface IGetNavigateReturn {
    to: string;
    options: INavigateOptions;
}

// const pathCache: Record<string, boolean> = {};
// export const validatePath = (path: string): boolean => {
//     if (!pathCache.hasOwnProperty(path)) {
//         const matchedPath = findMatchPath(path);
//         pathCache[path] = !!matchedPath;
//     }
//     console.log(path, "valid:", pathCache[path], "\n\n\n");
//     return pathCache[path];
// };

const prepend = (char: string, val?: string) => (
    val && !val.startsWith(char) ? char + val : val
);

const buildUrl = (urlInput: string | IUrlOpts): string => {
    return (typeof urlInput === "string"
        ? urlInput
        : [
            urlInput.path,
            prepend(
                "?",
                (typeof urlInput.query === "object"
                    ? stringifyQuery(urlInput.query)
                    : urlInput.query
                ),
            ),
            prepend("#", urlInput.hash),
        ].filter(Boolean).join("")
    );
};

export const getNavigateParams = (
    path: string | IUrlOpts,
    options: INavigateOptions = {},
    location: WindowLocation,
): IGetNavigateReturn => {
    const curState: ILocationState = location.state || {};
    const backPath = curState.from || options.backPathFallback || path;
    const savePath = `${location.pathname}${location.search}${location.hash}`;

    const goBack = !!options.back;
    const doSaveState = options.saveState ?? false;
    const doSavePath = options.savePath ?? false;

    if (goBack && curState.from) delete curState.from;
    const newState = Object.assign(
        {},
        doSavePath ? { from: savePath } : {},
        doSaveState ? curState : {},
        options?.state || {},
    );

    const usePath = buildUrl(goBack ? backPath : path);
    const loc = parsePath(usePath);

    const newQuery = typeof path === "object" && path.query
        ? typeof path.query === "string" ? parseQuery(path.query) : path.query
        : parseQuery(loc.search);
    const oldQuery: Record<string, unknown> = omit(
        (options.keepQuery
            ? parseQuery(location.search)
            : {}
        ),
        options.removeQuery || [],
    );

    if (!isEmpty(newQuery) || !isEmpty(oldQuery)) {
        loc.search = stringifyQuery({
            ...oldQuery,
            ...newQuery,
        });
    }

    const newPath = [
        usePath && slash(normalizeUrl(loc.pathname || location.pathname)),
        prepend("?", loc.search),
        prepend("#", loc.hash),
    ].filter(Boolean).join("");

    // if (options.validate) {
    //     const valid = validatePath(newPath);
    //     if (!valid) {
    //         const errMessage = `Found a broken link!  Invalid path requested: ${newPath}`;
    //         const err = new Error([
    //             errMessage,
    //             `From: ${location?.pathname || "unknown"}`,
    //         ].join("\n"));
    //         if (typeof window === "undefined") {
    //             throw err;
    //         }
    //         captureError(errMessage, {
    //             error: err,
    //             extra: {
    //                 from: savePath,
    //             },
    //         });
    //     }
    // }

    return {
        to: newPath,
        options: {
            ...options,
            state: newState,
        },
    };
};

export const navigate: INavigateFn = async (path, options = {}, location) => {
    const useLocation = location || window.location as WindowLocation;
    const curState: ILocationState = useLocation.state || window.history.state || {};

    if (typeof path === "number" && curState.from) {
        return window.history.go(path as number);
    }

    if (typeof path === "number" && path > 0) {
        throw new Error(`Invalid url defined for navigation: ${path}`);
    }

    const navPath = typeof path === "number"
        ? path === 0 && curState.from || ""
        : path;

    const {
        to,
        options: navigateOptions,
    } = getNavigateParams(navPath || "", options, useLocation);

    return gatsbyNavigate(to || "?", navigateOptions);
};