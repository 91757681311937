import isNil from "lodash/isNil";
import get from "lodash/get";

export const pullValue = <R = any>(selector: string | string[], obj: Record<string, any>, join?: string): R | null => {
    const getValue = (key: string) => (
        key ? get(obj, key) : obj
    );
    const val = selector instanceof Array
        ? selector.map(getValue)
        : getValue(selector);

    return (
        Array.isArray(selector) && Array.isArray(val) && !isNil(join)
            ? val.join(join)
            : val
    ) as R;
};

type Selector = {
    key: string | string[];
    join?: string;
}

export interface IReduceSelectors {
    [prop: string]: Selector;
}

type ToArray<T> = T extends Array<any> ? T : T[];

export const arrayify = <T>(input: T): ToArray<T> => (
    (Array.isArray(input) ? input : [input]) as ToArray<T>
);

export const arrayToObject = <T>(arr: Array<T>): Record<string, T> => {
    return arr.reduce((acc, val, i) => (
        Object.assign(acc, {
            [i]: val,
        })
    ), {} as Record<string, T>);
};

type NameValue = { name: string, value?: string | null };

export const nameValueToObj = (input?: Readonly<NameValue[]> | null): Record<string, string | undefined | null> => {
    return (input || []).reduce((acc, { name, value }) => (
        Object.assign(acc, {
            [name]: value,
        })
    ), {} as Record<string, string>);
};

export const objHasKeys = (orig: Record<string, unknown>, compareTo: Record<string, unknown>): boolean => {
    return Object.keys(compareTo).every((key) => {
        return orig.hasOwnProperty(key);
    });
};

export const isEqual = (orig: Record<string, unknown>, compareTo: Record<string, unknown>): boolean => {
    return Object.keys(compareTo).every((key) => {
        if (!orig.hasOwnProperty(key)) return false;
        return orig[key] === compareTo[key];
    });
};

export const isEmpty = (obj?: Record<string, unknown> | null): boolean => (
    !!obj && Object.keys(obj).length === 0
);