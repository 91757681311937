import React from "react";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

interface IToastCloseButtonProps {
    onClick: () => void;
}

export const ToastCloseButton: React.FC<IToastCloseButtonProps> = ({ onClick }) => {
    return (
        <IconButton
            aria-label="close-toast"
            onClick={onClick}
        >
            <CloseIcon />
        </IconButton>
    );
};