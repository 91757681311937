import React from "react";
import { navigate } from "@utils/navigate";
import { LayoutModel } from "~/model/layout";
import { getViewModel, isViewComponent } from "./view-model";

import type {
    ViewComponent,
    ViewWrapper,
    ICreateViewArgs,
} from "./types";

export type {
    ICreateViewArgs,
    ViewWrapper,
    ViewComponent,
    ViewComponentProps,
    ViewModelElement,
    PageProps,
    ILocationState,
    WindowLocation,
} from "./types";

export type { ViewModel } from "./view-model";

export { isViewComponent };

export function createView<
    TContext extends Record<string, unknown> = Record<string, unknown>,
    TComponent extends ViewComponent<any> = ViewComponent<any>,
    TLayout extends LayoutModel = LayoutModel,
>(
    component: TComponent,
    options = {} as ICreateViewArgs<TContext, TComponent, TLayout>,
) {
    const {
        internalScroll = false,
        internalPad = false,

        layout = new LayoutModel(),
        layoutProps,

        viewWrapper,

        showBack = false,
        backTo,
    } = options;

    return getViewModel(
        component,
        {
            layout,
            layoutProps,

            viewWrapper,

            showBack,
            backTo,

            internalPad,
            internalScroll,
        },
        options,
    );
}

export function createViewWrapper<
    TComponent extends ViewComponent<any> = ViewComponent<any>,
>(Component: TComponent) {
    const ViewWrapper = ((props) => {
        return <Component {...props} navigate={navigate} />;
    }) as ViewWrapper<TComponent>;

    return ViewWrapper;
}