import { darken, lighten } from "@material-ui/core/styles/colorManipulator";
import createPalette, { PaletteOptions } from "@material-ui/core/styles/createPalette";
import deepmerge from "deepmerge";

const paletteOptions: PaletteOptions = {
    background: {
        default: "#FFFFFF",
        secondary: {
            main: "#222429",
        },
        paper: "#F5F5F7",
        gradient: {
            main: "linear-gradient(180deg, #353A40 0%, #111518 100%)",
        },
    },
    primary: {
        main: "#111518",
        contrastText: "#111518",
    },
    secondary: {
        main: "#111518",
        contrastText: "#FFFFFF",
    },
    success: {
        main: "#2DAF3A",
    },
    error: {
        main: "#D32030",
    },
    text: {
        primary: "#000000",
        secondary: lighten("#000000", .25),
        contrast: "#FFFFFF",
    },
};

const paletteGen = createPalette(paletteOptions);

const border = paletteGen.augmentColor({
    main: "#D8D8D9",
});
const borderInternal = paletteGen.augmentColor({
    main: "rgba(0, 0, 0, 0.12);",
});

Object.assign(border, {
    dark: darken(border.main, .5),
});

export const palette = deepmerge(paletteGen, { border, borderInternal }) as PaletteOptions;
